<template>
  <div>
    <v-card class="transparent" :loading="loading">
      <v-card-title> 
        <h2>User Details</h2>  
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text>
        <v-row >
          <v-col cols="12" md="6">
            <h3 class="text-h5 font-weight-bold white--text">Information</h3>
            <v-row align="center"  class="mt-4 white--text">
              <v-col cols="12">
                <div class="d-flex justify-space-around">
                  <span class="text-h6 font-weight-bold">
                    <v-icon color="white">mdi-account</v-icon>
                    {{ user.fullName }}
                  </span>
                  <span class="text-h6 font-weight-bold">
                    <v-icon color="white">mdi-view-compact</v-icon>
                    {{ companyName }}
                  </span>
                </div>
              </v-col>
              <v-col cols="12">
                <div class="d-flex justify-space-around">
                  <span class="text-h6 font-weight-bold">
                    <v-icon color="white">mdi-phone</v-icon>
                    <span dir="ltr">
                      {{ user.phoneNumber }}
                    </span>
                  </span>
                  <span class="text-h6 font-weight-bold">
                    <v-icon color="white">mdi-email</v-icon>
                    {{ user.email }}
                  </span>
                </div>
              </v-col>
              <v-col cols="12">
                <div class="d-flex justify-space-around">
                  <span class="text-h6 font-weight-bold">
                    <v-icon color="white">mdi-account-cash </v-icon>
                    <span class="mr-2" dir="ltr">
                      {{ $service.formatCurrency(user.salary) }}
                    </span>
                  </span>
                </div>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" md="6">
            <h3 class="text-h5 font-weight-bold white--text">Attachments</h3>
            <v-sheet class="mx-auto" max-width="800">
              <v-slide-group v-model="model" class="pa-4" mandatory show-arrows>
                <v-slide-item
                  v-for="(attach, n) in user.userAttachement"
                  :key="n"
                  v-slot="{}"
                >
                    
                  <v-card class="mx-1" height="280" width="250">
                      <span class="text-h6">
                        {{attach.name}}
                    </span>
                    <v-img
                      :lazy-src="$service.url + attach.path"
                      :src="$service.url + attach.path"
                      width="250"
                      height="250"
                    >
                    </v-img>
                  </v-card>
                </v-slide-item>
              </v-slide-group>
            </v-sheet>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </div>
</template>
<script>
export default {
    computed:{
        companyName(){
            if(this.user.company !== undefined) {
                return this.user.company.name  
            } else {
                return this.user.companyName 
            }
        }
    },

  data() {
    return {
      loading: false,
      user: {},
      model: null,
    };
  },
  created() {
    this.loading = true;
    this.$http
      .get(`${this.$route.params.type}/${this.$route.params.id}`)
      .then((res) => {
        this.user = res.data.result;
        this.loading = false;
      });
  },
};
</script>